<template>
  <component :is="layout" />
  <Alert />
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import Alert from '@/components/Alert'

export default {
  components: { DefaultLayout, EmptyLayout, Alert },
  data: () => ({
    layout: 'DefaultLayout'
  }),
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.meta.layout) {
          this.layout = route.meta.layout
        }
      }
    }
  }
}
</script>

<style lang="scss">
input,
button {
  outline: none !important;
  box-shadow: none !important;
}
.btn-block {
  display: block;
  width: 100%;
}
.mr-2 {
  margin-right: 10px;
}
.ml-2 {
  margin-left: 10px;
}
.mt-14 {
  margin-top: 70px !important;
}
.btn-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.btn-info:hover {
  color: #fff !important;
}
.btn-outline-info {
  color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.btn-outline-info:hover {
  color: #fff !important;
  background-color: #17a2b8 !important;
}
.form {
  margin-top: 20px;

  &__control {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.control {
  position: relative;

  &__error {
    padding-top: 16px;
    padding-left: 12px;
    color: #fa3131;
    font-size: 12px;
  }

  input {
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 15px;
    padding: 20px 20px 20px 40px;
    font-size: 14px;
    color: #848194;
    background: #fff;

    &:focus {
      outline: none;
    }
  }
}

.questionModal {
  .confirmBtn {
    margin-left: 20px;
    min-width: 100px;
  }

  .btnBl {
    display: flex;
    justify-content: flex-end;
  }
}

.enabledStatus,
.whiteListedStatus {
  color: #198754 !important;
}
.onHoldStatus {
  color: #bd8f05 !important;
}
.disabledStatus,
.deletedStatus {
  color: #dc3545 !important;
}
.noBreak {
  white-space: nowrap;
}
.cursorPointer {
  cursor: pointer;
}
.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: inline-block;
}
.bgCustomSuccess {
  background-color: #b6d7a8 !important;
}
.bgCustomWarning {
  background-color: #ffe599 !important;
}
.bgCustomDanger {
  background-color: #ea9999 !important;
}
.relative {
  position: relative;
}
</style>
