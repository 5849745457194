export default {
  namespaced: true,
  state: {
    searchData: null,
    searchAccount: null,
    activationsData: null,
    activationsPage: 1,
    reasons: {},
    notesData: null,
    notesPage: 1,
    statusChangeHistoryData: null,
    statusChangeHistoryPage: 1
  },
  mutations: {
    setSearchData: (state, val) => {
      state.searchAccount = null
      state.searchData = val
      if (val && val.length === 1) {
        state.searchAccount = val[0]
      }
    },
    setSearchAccount: (state, account) => {
      state.searchAccount = account
    },
    setActivationsData: (state, activationsData) => {
      state.activationsData = activationsData
    },
    setActivationsPage: (state, page) => {
      state.activationsPage = page
    },
    reasons: (state, reasons) => {
      for (const reason of reasons) {
        if (!state.reasons[reason.type]) {
          state.reasons[reason.type] = {}
        }
        state.reasons[reason.type][reason.id] = reason.reason
      }
    },
    setNotesData: (state, notes) => {
      state.notesData = notes
    },
    setNotesPage: (state, page) => {
      state.notesPage = page
    },
    setStatusChangeHistoryData: (state, history) => {
      state.statusChangeHistoryData = history
    },
    setStatusChangeHistoryPage: (state, page) => {
      state.statusChangeHistoryPage = page
    }
  }
}
