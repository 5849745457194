import { useStore } from 'vuex'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export function useSearch() {
  const store = useStore()
  const { initApiInst, actUserApi, actActivationsApi, actNotesApi } = useActAdminApi()
  const { handleCommonErrors } = useErrorHandler()

  const getSearchData = async (uid, email = '', orderId = '', activationData = '') => {
    store.commit('search/setActivationsPage', 1)
    store.commit('search/setNotesPage', 1)
    store.commit('search/setStatusChangeHistoryPage', 1)
    if (!uid && !email && !orderId && !activationData) return
    try {
      await initApiInst()
      const searchData = await actUserApi.value.getUserInfo({ email, uid, orderId, activationData })
      store.commit('search/setSearchData', searchData)
      if (searchData.length === 1) {
        await getUserActivations(searchData[0].user.id)
        await getUserNotes(searchData[0].user.id)
        await getUserStatusChangeHistory(searchData[0].user.id)
      }
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setSearchData', null)
      store.commit('search/setActivationsData', null)
      store.commit('search/setNotesData', null)
      store.commit('search/setStatusChangeHistoryData', null)
    }
  }

  const getUserActivations = async (userId, page = 1) => {
    try {
      await initApiInst()
      const activationsData = await actActivationsApi.value.getActivations(userId, { page })
      store.commit('search/setActivationsData', activationsData)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setActivationsData', null)
    }
  }

  const getUserNotes = async (userId, page = 1) => {
    try {
      await initApiInst()
      const notesData = await actNotesApi.value.getSupportNotesByUserId(userId, { page })
      store.commit('search/setNotesData', notesData)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setNotesData', null)
    }
  }

  const getUserStatusChangeHistory = async (userId, page = 1) => {
    try {
      await initApiInst()
      const statusChangeHistoryData = await actUserApi.value.getUserStatusChangeHistory(userId, { page })
      store.commit('search/setStatusChangeHistoryData', statusChangeHistoryData)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setStatusChangeHistoryData', null)
    }
  }

  return { getSearchData, getUserActivations, getUserNotes, getUserStatusChangeHistory }
}
