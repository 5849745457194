<template>
  <b-row v-if="notesData && notesData.notes" class="notesWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Support Notes</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="align-middle">Date</th>
              <th class="align-middle">Note</th>
              <th class="align-middle">Author</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in notesArr" :key="index" class="">
              <td class="align-middle tableLimitedTd">
                <div>{{ format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss') }}</div>
              </td>
              <td class="align-middle"><div v-html="addBreakLinesToText(row.note)" /></td>
              <td class="align-middle tableLimitedTd">
                <div>{{ row.author }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="this.pagesCount"
          :currentPage="this.currentPage"
          :limit="this.limit"
          :itemsCount="this.itemsCount"
          class="pagination"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { addBreakLinesToText } from '@/helpers/text'
import Paginator from '@/components/Paginator.vue'

export default {
  components: {
    Paginator
  },
  data() {
    return {
      format,
      addBreakLinesToText,
      notesArr: [],
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    },
    notesData() {
      return this.$store.state.search.notesData
    },
    searchAccount() {
      return this.$store.state.search.searchAccount
    }
  },
  setup() {
    const { getUserNotes } = useSearch()
    const { initApiInst } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return {
      getUserNotes,
      initApiInst,
      handleCommonErrors
    }
  },
  watch: {
    notesData(val) {
      if (val) {
        this.formNotesArr()
      }
    }
  },
  mounted() {
    this.formNotesArr()
  },
  methods: {
    formNotesArr() {
      this.clearData()
      if (this.notesData !== null && this.notesData.notes.length > 0) {
        for (const note of this.notesData.notes) {
          this.notesArr.push(note)
        }
        this.currentPage = this.notesData.page
        this.limit = this.notesData.limit
        this.pagesCount = this.notesData.pages
        this.itemsCount = this.notesData.total
      }
    },
    clearData() {
      this.notesArr = []
      this.currentPage = 1
      this.limit = 10
      this.pagesCount = 1
      this.itemsCount = null
    },
    showNextPage() {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage() {
      this.openPage(this.currentPage - 1)
    },
    selectPage(id) {
      this.openPage(id)
    },
    async openPage(page) {
      this.$store.commit('loaders/setCommonLoading', true)
      this.$store.commit('search/setNotesPage', page)
      await this.getUserNotes(this.searchAccount.user.id, page)
      this.$store.commit('loaders/setCommonLoading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.notesWrap {
  margin-top: 30px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid #717171;
  }
  .tableLimitedTd {
    width: 280px;
    min-width: 180px;
  }
}
</style>
