<template>
  <b-row v-if="activationsData && activationsData.activations" class="activationsWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Activations</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="align-middle">Date</th>
              <th class="align-middle">Activation #</th>
              <th class="align-middle">Number</th>
              <th class="align-middle">Service/<br />Country</th>
              <th class="align-middle">Statuses</th>
              <th class="align-middle">Message</th>
              <th class="align-middle">Code</th>
              <th class="align-middle">Price</th>
              <th class="align-middle">Report</th>
              <th class="align-middle">Report status</th>
              <th class="align-middle">Report review status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in activationsArr" :key="index" class="">
              <td class="align-middle">{{ format(new Date(row.created_at * 1000), 'yyyy-MM-dd HH:mm:ss') }}</td>
              <td
                @click="copyToCb($refs['activationId' + index][0], row.activation_id)"
                class="cursorPointer align-middle"
              >
                <span :ref="`activationId${index}`">{{ row.activation_id }}</span>
              </td>
              <td @click="copyToCb($refs['phone' + index][0], row.phone)" class="cursorPointer align-middle">
                <span :ref="`phone${index}`">{{ row.phone }}</span>
              </td>
              <td class="align-middle">
                {{ row.service_name }} ({{ row.service_id.toLowerCase() }})/<br />
                {{ row.country_name }} ({{ row.country_id.toLowerCase() }})
              </td>
              <td class="align-middle">
                {{ row.activation_status }}/<br />{{ row.sms_status }}/<br />{{ row.billing_status }}
              </td>
              <td class="align-middle">{{ row.sms_text }}</td>
              <td class="align-middle">{{ row.sms_code }}</td>
              <td class="align-middle">${{ Number(row.price) / 100 }}</td>
              <td class="text-center align-middle">
                <b-button
                  v-if="row.report_status"
                  @click="handleViewReportClick(row)"
                  variant="outline-secondary"
                  size="sm"
                >
                  View
                </b-button>
                <span v-else>&mdash;</span>
              </td>
              <td class="align-middle">
                <span v-if="row.report_status" :class="getStatusColorClass(row.report_status)">
                  {{ splitCamelCase(row.report_status) }}
                </span>
              </td>
              <td class="align-middle">
                <span v-if="row.report_review_status" :class="getReviewStatusColorClass(row.report_review_status)">
                  {{ splitCamelCase(row.report_review_status) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="this.pagesCount"
          :currentPage="this.currentPage"
          :limit="this.limit"
          :itemsCount="this.itemsCount"
          class="pagination"
        />
      </div>
    </b-col>
  </b-row>
  <ViewReportModal
    :show-view-report-modal="showViewReportModal"
    :info-modal="true"
    :viewed-report-id="viewedReportId"
    @close-view-report-modal="closeViewReportModal"
  />
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useReport } from '@/use/report'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import Paginator from '@/components/Paginator.vue'
import { useCopy } from '@/use/copy'
import { useHelper } from '@/use/helper'
import ViewReportModal from '@/components/Reports/ViewReportModal/Index.vue'

export default {
  components: {
    ViewReportModal,
    Paginator
  },
  data() {
    return {
      format,
      activationsArr: [],
      activationId: null,
      activationRefundModal: false,
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null,
      selectActivationReasonModal: false,
      selectedReason: null,
      showViewReportModal: false,
      viewedReportId: null
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    },
    activationsData() {
      return this.$store.state.search.activationsData
    },
    searchAccount() {
      return this.$store.state.search.searchAccount
    }
  },
  setup() {
    const { getSearchData, getUserActivations } = useSearch()
    const { initApiInst } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    const { splitCamelCase } = useHelper()
    const { getReviewStatusColorClass, getStatusColorClass } = useReport()
    return {
      getSearchData,
      getUserActivations,
      initApiInst,
      handleCommonErrors,
      copyToCb,
      splitCamelCase,
      getReviewStatusColorClass,
      getStatusColorClass
    }
  },
  watch: {
    activationsData(val) {
      if (val) {
        this.formActivationsArr()
      }
    }
  },
  mounted() {
    this.formActivationsArr()
  },
  methods: {
    formActivationsArr() {
      this.clearData()
      if (this.activationsData !== null && this.activationsData.activations.length > 0) {
        for (const activation of this.activationsData.activations) {
          this.activationsArr.push(activation)
        }
        this.currentPage = this.activationsData.page
        this.limit = this.activationsData.limit
        this.pagesCount = this.activationsData.pages
        this.itemsCount = this.activationsData.total
      }
    },
    clearData() {
      this.activationsArr = []
      this.currentPage = 1
      this.limit = 10
      this.pagesCount = 1
      this.itemsCount = null
      this.activationId = null
      this.activationRefundModal = false
      this.selectActivationReasonModal = false
    },
    showNextPage() {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage() {
      this.openPage(this.currentPage - 1)
    },
    selectPage(id) {
      this.openPage(id)
    },
    async openPage(page) {
      this.$store.commit('loaders/setCommonLoading', true)
      this.$store.commit('search/setActivationsPage', page)
      await this.getUserActivations(this.searchAccount.user.id, page)
      this.$store.commit('loaders/setCommonLoading', false)
    },
    handleViewReportClick(activation) {
      if (activation.report_status === 'inReview') {
        const nowTimestamp = Math.ceil(new Date().getTime() / 1000)
        if (activation.expire_at < nowTimestamp) {
          this.$router.push({ name: 'Reports', query: { activation_id: activation.activation_id } })
        } else {
          const secondsLeft = activation.expire_at - nowTimestamp
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: `You can view the report after an activation will be expired. Please try again after ${secondsLeft} seconds`,
            timeout: 5000,
            type: 'warning',
            name: 'warning_open_report'
          })
        }
      } else {
        this.showViewReportModal = true
        this.viewedReportId = activation.activation_id
      }
    },
    closeViewReportModal() {
      this.showViewReportModal = false
      this.viewedReportId = null
    }
  }
}
</script>

<style lang="scss" scoped>
.activationsWrap {
  margin-top: 30px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid #717171;
  }
}
.cursorPointer {
  cursor: pointer;
}
</style>
