export default {
  namespaced: true,
  state: {
    statusChangeReasons: null
  },
  mutations: {
    setStatusChangeReasons: (state, data) => {
      state.statusChangeReasons = data
    }
  }
}
