import { useStore } from 'vuex'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export function useCatalog() {
  const store = useStore()
  const { initApiInst, actCatalogApi } = useActAdminApi()
  const { handleCommonErrors } = useErrorHandler()

  const getStatusChangeReasons = async () => {
    if (store.state.catalog.statusChangeReasons) return
    try {
      await initApiInst()
      const reasons = await actCatalogApi.value.getStatusChangeReasons()
      store.commit('catalog/setStatusChangeReasons', reasons)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setStatusChangeReasons', null)
    }
  }

  return { getStatusChangeReasons }
}
