<template>
  <b-row v-if="historyData && historyData.history" class="statusChangeHistoryWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Status Change History</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="align-middle">Date</th>
              <th class="align-middle">Status</th>
              <th class="align-middle">Reason</th>
              <th class="align-middle">Details</th>
              <th class="align-middle">Author</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in historyArr" :key="index" class="">
              <td class="align-middle tableLimitedTd">
                <div>{{ format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss') }}</div>
              </td>
              <td class="align-middle" :class="getStatusClassName(row.status)">
                {{ getStatusName(row.status) }}
              </td>
              <td class="align-middle">{{ row.reason ?? '' }}</td>
              <td class="align-middle"><div v-html="addBreakLinesToText(row.note)" /></td>
              <td class="align-middle tableLimitedTd">
                <div>{{ row.author ?? '' }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="this.pagesCount"
          :currentPage="this.currentPage"
          :limit="this.limit"
          :itemsCount="this.itemsCount"
          class="pagination"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { addBreakLinesToText } from '@/helpers/text'
import Paginator from '@/components/Paginator.vue'

export default {
  components: {
    Paginator
  },
  data() {
    return {
      format,
      addBreakLinesToText,
      historyArr: [],
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null,
      statusOptions: ['Disabled', 'Enabled', 'On Hold', 'White Listed']
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    },
    historyData() {
      return this.$store.state.search.statusChangeHistoryData
    },
    searchAccount() {
      return this.$store.state.search.searchAccount
    }
  },
  setup() {
    const { getUserStatusChangeHistory } = useSearch()
    const { initApiInst } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return {
      getUserStatusChangeHistory,
      initApiInst,
      handleCommonErrors
    }
  },
  watch: {
    historyData(val) {
      if (val) {
        this.formHistoryArr()
      }
    }
  },
  mounted() {
    this.formHistoryArr()
  },
  methods: {
    formHistoryArr() {
      this.clearData()
      if (this.historyData !== null && this.historyData.history.length > 0) {
        for (const item of this.historyData.history) {
          this.historyArr.push(item)
        }
        this.currentPage = this.historyData.page
        this.limit = this.historyData.limit
        this.pagesCount = this.historyData.pages
        this.itemsCount = this.historyData.total
      }
    },
    clearData() {
      this.historyArr = []
      this.currentPage = 1
      this.limit = 10
      this.pagesCount = 1
      this.itemsCount = null
    },
    showNextPage() {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage() {
      this.openPage(this.currentPage - 1)
    },
    selectPage(id) {
      this.openPage(id)
    },
    async openPage(page) {
      this.$store.commit('loaders/setCommonLoading', true)
      this.$store.commit('search/setStatusChangeHistoryPage', page)
      await this.getUserStatusChangeHistory(this.searchAccount.user.id, page)
      this.$store.commit('loaders/setCommonLoading', false)
    },
    getStatusName(statusId) {
      return this.statusOptions[statusId]
    },
    getStatusClassName(statusId) {
      const statusOneWord = this.statusOptions[statusId].replace(' ', '')
      return statusOneWord.charAt(0).toLowerCase() + statusOneWord.slice(1) + 'Status'
    }
  }
}
</script>

<style lang="scss" scoped>
.statusChangeHistoryWrap {
  margin-top: 30px;

  .header {
    font-size: 24px;
    border-bottom: 1px solid #717171;
  }
  .tableLimitedTd {
    width: 280px;
    min-width: 180px;
  }
}
</style>
