<template>
  <b-button
    @click="addNoteModal = true"
    :disabled="loading"
    class="addNoteBtn mb-3 mt-4 mb-xl-5"
    variant="outline-info"
  >
    <b-spinner v-if="loading" small variant="info" />
    <span v-else>Add support note</span>
  </b-button>

  <b-modal v-model="addNoteModal" title="Add Support Note" class="questionModal" centered hide-footer>
    <div>
      <b-form-textarea v-model="noteText" placeholder="Enter Note text" rows="5" class="mb-4" />
    </div>
    <div class="btnBl">
      <b-button @click="closeModal" variant="light">Cancel</b-button>
      <b-button @click="addNote" :disabled="loading" variant="info" class="confirmBtn">
        <b-spinner v-if="loading" small variant="info" />
        <span v-else>Save Note</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export default {
  props: {
    account: Object
  },
  data() {
    return {
      user_id: null,
      addNoteModal: false,
      noteText: ''
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    }
  },
  setup() {
    const { getUserNotes } = useSearch()
    const { initApiInst, actNotesApi } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return { getUserNotes, initApiInst, actNotesApi, handleCommonErrors }
  },
  methods: {
    async addNote() {
      if (!this.account.id) {
        this.closeModal()
        return
      }
      if (!this.noteText) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Note text is empty',
          timeout: 2000,
          name: 'empty_note_warning'
        })
        return
      }

      try {
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.actNotesApi.createSupportNote({
          noteRecord: { user_id: this.account.id, note: this.noteText }
        })
        if (resp && resp.result === 'success') {
          await this.getUserNotes(this.account.id)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Note added successfully',
            timeout: 3000,
            type: 'success',
            name: 'note_added'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.closeModal()
        this.$store.commit('loaders/setCommonLoading', false)
      }
    },
    closeModal() {
      this.addNoteModal = false
      this.noteText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.addNoteBtn {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
