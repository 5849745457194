<template>
  <b-row v-if="account" class="userInfoWrap">
    <b-col lg="4" class="userInfo my-3">
      <div v-if="account.email" @click="copyToCb($refs.email, account.email)" class="email">
        <span class="greyText">Email:</span> <span ref="email">{{ account.email }}</span>
      </div>
      <div v-if="account.fbuid" @click="copyToCb($refs.uid, account.fbuid)" class="uid">
        <span class="greyText">User Id:</span> <span ref="uid">{{ account.fbuid }}</span>
      </div>
      <div>
        <span class="greyText">Country:</span> {{ account.last_country ? account.last_country.toUpperCase() : '' }}
      </div>
      <div>
        <span class="greyText">Balance (total/reserved):</span>
        ${{ account.balance_total ? account.balance_total / 100 : 0 }} / ${{
          account.balance_reserved ? account.balance_reserved / 100 : 0
        }}
      </div>
      <div>
        <span class="greyText">Balance from SQL:</span> ${{ account.balance_sql ? account.balance_sql / 100 : 0 }}
      </div>
      <div><span class="greyText">Discount percent:</span> {{ account.discount_percent ?? 0 }}%</div>
      <div><span class="greyText">Total Activations:</span> {{ account.activations_count ?? 0 }}</div>
      <div>
        <span class="greyText">Total Reports:</span>
        {{ account.reports_count ?? 0 }} (<span
          :class="{
            'text-success': account.reports_ratio > 0 && account.reports_ratio <= 10,
            'text-warning': account.reports_ratio > 10 && account.reports_ratio <= 30,
            'text-danger': account.reports_ratio > 30
          }"
          >{{ account.reports_ratio ?? 0 }}% ratio</span
        >)
      </div>
      <div>
        <span class="greyText">Approved reports:</span>
        {{ account.approved_reports_count ?? 0 }} (<span
          :class="{
            'text-success': account.approved_reports_ratio >= 80,
            'text-warning': account.approved_reports_ratio >= 30 && account.approved_reports_ratio < 80,
            'text-danger': account.approved_reports_ratio < 30 && account.approved_reports_ratio > 0
          }"
          >{{ account.approved_reports_ratio ?? 0 }}% ratio</span
        >)
      </div>
    </b-col>
    <b-col lg="3" class="my-3">
      <b-row>
        <b-col xs="6" class="px-xl-2">
          <b-button
            @click="deleteAccountModal = true"
            :disabled="account.status === 'deleted' || loading"
            class="accountActionBtn mb-3 mb-xl-5"
            variant="outline-danger"
          >
            <b-spinner v-if="loading" small variant="danger" />
            <span v-else>Delete account</span>
          </b-button>
        </b-col>
        <b-col xs="6" class="px-xl-2">
          <b-button
            @click="refundAllBalanceModal = true"
            :disabled="loading"
            class="accountActionBtn mb-3 mb-xl-5"
            variant="outline-primary"
          >
            <b-spinner v-if="loading" small variant="primary" />
            <span v-else>Refund balance</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" class="px-xl-2">
          <StatusSelect :account="account" />
          <AddNote :account="account" />
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="8" xl="3" class="statisticsButtonsWrap my-3">
      <a :href="grafanaUrl + account.fbuid" target="_blank" class="btn btn-outline-success btn-block mb-3 mb-xl-2 mr-2">
        Statistics ⟶
      </a>
      <a :href="crispUrl" target="_blank" class="btn btn-outline-success btn-block mb-3 mb-xl-2">Chat / Email ⟶</a>
    </b-col>
  </b-row>

  <b-modal
    v-model="deleteAccountModal"
    @keydown.enter="deleteAccount"
    title="Are you sure?"
    class="questionModal"
    centered
    hide-footer
  >
    <div class="btnBl">
      <b-button @click="closeModal" variant="light">Cancel</b-button>
      <b-button @click="deleteAccount" variant="danger" class="confirmBtn">Delete Account</b-button>
    </div>
  </b-modal>

  <b-modal
    v-model="refundAllBalanceModal"
    @keydown.enter="refundAllBalance"
    title="Are you sure?"
    class="questionModal"
    centered
    hide-footer
  >
    <div class="btnBl">
      <b-button @click="closeModal" variant="light">Cancel</b-button>
      <b-button @click="refundAllBalance" variant="danger" class="confirmBtn">Refund all balance</b-button>
    </div>
  </b-modal>
</template>

<script>
import StatusSelect from '@/components/StatusSelect'
import AddNote from '@/components/Notes/AddNote'
import { useSearch } from '@/use/search'
import { useActAdminApi } from '@/use/actAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { useCopy } from '@/use/copy'

export default {
  props: {
    account: Object
  },
  emits: ['searchUser', 'searchReset'],
  components: { StatusSelect, AddNote },
  data() {
    return {
      crispUrl: process.env.VUE_APP_CRISP_URL,
      grafanaUrl: process.env.VUE_APP_GRAFANA_URL,
      deleteAccountModal: false,
      refundAllBalanceModal: false
    }
  },
  computed: {
    loading() {
      return this.$store.state.loaders.commonLoading
    }
  },
  setup() {
    const { getSearchData } = useSearch()
    const { initApiInst, actUserApi, actPurchasesApi } = useActAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    return { getSearchData, initApiInst, actUserApi, actPurchasesApi, handleCommonErrors, copyToCb }
  },
  methods: {
    closeModal() {
      this.deleteAccountModal = this.refundAllBalanceModal = false
    },
    async deleteAccount() {
      try {
        this.closeModal()
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.actUserApi.deleteUser(this.account.id)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.account.fbuid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Account successfully deleted',
            timeout: 3000,
            type: 'success',
            name: 'deleted'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
      }
    },
    async refundAllBalance() {
      try {
        this.closeModal()
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.actPurchasesApi.refundAllPurchase(this.account.id)
        if (resp && resp.result === 'success') {
          const _this = this
          setTimeout(async () => {
            await _this.getSearchData(_this.account.fbuid)
          }, 3000)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'All balance refunded',
            timeout: 3000,
            type: 'success',
            name: 'refunded'
          })
        }
      } catch (e) {
        if (e.status === 409) {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'The refund already exists',
            timeout: 3000,
            name: 'refund_exists'
          })
        } else {
          this.handleCommonErrors(e)
        }
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.userInfoWrap {
  margin-top: 60px;
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }

  .userInfo {
    > div {
      font-size: 18px;
      margin-bottom: 7px;
      &.email,
      &.apiKey,
      &.uid {
        cursor: pointer;
      }
      span.greyText {
        color: #7e7e7e;
      }
    }
  }
  .accountActionBtn {
    width: 100%;
    display: block;
    padding-left: 4px;
    padding-right: 4px;
  }
  .mr-10px {
    margin-right: 10px;
  }
  .deletedAccount {
    display: flex;
    align-items: center;
    color: #ff4757;
    font-size: 20px;
  }
  .statisticsButtonsWrap {
    a {
      max-width: 180px;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
</style>
